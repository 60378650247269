import React from 'react';
import {dropDownSelection, getUserData, STRINGS} from "../../../utils/base";
import JobPost from "../jobPost";
import {API} from "../../../utils/services";

const JobPostDetailModal = (props) => {
    let {openJobPostDetails, onClose, data} = props;

    const handleUpdate = () => {
        API.JOBS.jobApproved(data.id, data).then((response) => {
            if (response.status) {
                alert("Job Approved Successfully");
                window.location.href = STRINGS.ROUTES.DASHBOARD.JOB_LIST
            }
        });
    }

    return (
        <React.Fragment>
            {
                dropDownSelection()
            }
            <div
                className={`modal fade profile-modal-container ${openJobPostDetails ? `show` : ``}`}
                id="about-modal"
                tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered"
                     role="document">
                    <div className="modal-content">
                        <button type="button" className="close"
                                data-dismiss="modal"
                                aria-label="Close">
                                                                    <span aria-hidden="true"
                                                                          onClick={() => onClose()}>&times;</span>
                        </button>
                        <div className="modal-body">
                            <section className="card">
                                <header className="card-header">
                                    {/*<h2 className="card-title mb-0">Edit About</h2>*/}
                                </header>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <JobPost data={data} onClose={()=> onClose()}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    {
                                        //data.status === "Waiting for Approval" &&
                                         getUserData().type === STRINGS.USER_TYPE.ADMIN_TYPE && (
                                            <div className="row">
                                                <div className="col-12">
                                                    <div
                                                        className="profile-action-btn d-flex flex-wrap align-content-center justify-content-between">
                                                        <button
                                                            onClick={handleUpdate}
                                                            className="ht-btn theme-btn theme-btn-two mb-xs-20">Approved
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"/>
        </React.Fragment>
    );
};

export default JobPostDetailModal;