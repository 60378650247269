import React, {useEffect, useState} from 'react';
import {Badge, IconButton, withStyles} from "@material-ui/core";
import NotificationsIcon from '@material-ui/icons/Notifications';
import {parseDate, parseDateAndTime, STRINGS} from "../../utils/base";
import DrawerPopUp from "../Drawer";
import {API} from "../../utils/services";
import {Toast, ToastBody, ToastHeader} from "react-bootstrap";

const StyleNotificationBadge = withStyles((theme) => ({
    badge: {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: `0 4px`,
    },
}))(Badge);

const defaultState = {
    openDrawer: false,
    isResponse: false,
    pageNo: 1,
    counter: 0,
    myNotifications: []
}

const NotificationBadge = (props) => {
    let {logoType} = props;
    const [init, setInit] = useState(defaultState);
    let {openDrawer, pageNo, myNotifications, isResponse,counter} = init;

    useEffect(() => {
        const getMyNotification = (page) => {
            API.JOBS.getMyNotification(page).then((response) => {
                // console.log(response);
                let {status, error, data} = response;
                if (status) {
                    // console.log("myJob", data);
                    setInit({
                        ...init,
                        myNotifications: data,
                        counter:data.length,
                        isResponse: true
                    })
                } else {
                    alert(error)
                }
            })
        }
        getMyNotification(pageNo)
    }, [pageNo])

    const handleOpenDrawer = () => {
        setInit({
            ...init,
            openDrawer: true
        })
    }
    const handleCloseDrawer = () => {
        setInit({
            ...init,
            openDrawer: false
        })
    }

    const NotificationContent = () => {
        // console.log("NotificationContent", myNotifications)
        return (
            <div className="w-100 p-1">
                <div className="col-xl-12 col-12">
                    <div className="notifications-applications mb-20 mb-sm-80 mb-xs-80">
                        <div className="notifications-heading">
                            <h3>Notifications</h3>
                        </div>
                        <div className="notifications-main-block">
                            <div className="notification-listing ov-des" style={{padding: "15px",
                                overflow: "auto"}}>

                                {
                                    isResponse && (
                                        myNotifications.length > 0 ?

                                            myNotifications.map((notIf) =>
                                                <Toast>
                                                    <ToastHeader className="gradiant-css text-white">
                                                        <img src="#" className="rounded mr-2" alt="" />
                                                        <strong className="mr-auto">Notification</strong>
                                                        <small>{parseDateAndTime(parseDate(new Date(notIf.createdOn)))}</small>
                                                    </ToastHeader>
                                                    <ToastBody>{notIf.message}</ToastBody>
                                                </Toast>
                                            )
                                            :
                                            <div className="empty">
                                                <h3>There are no notifications</h3>
                                                <p>Your latest notifications will be displayed here</p>
                                            </div>
                                    )
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="d-flex justify-content-center">
            <IconButton onClick={handleOpenDrawer}>
                <StyleNotificationBadge badgeContent={counter} color={"error"}>
                    <NotificationsIcon className={`${
                        logoType === STRINGS.TYPES.LOGO_TYPE.MAIN && "text-white"
                    }`}/>
                </StyleNotificationBadge>
            </IconButton>
            <DrawerPopUp
                content={<NotificationContent/>}
                open={openDrawer}
                onClose={handleCloseDrawer}/>
        </div>
    );
};

export default NotificationBadge;